<template>
  <v-card>
    <v-card-title class="lightgray">
      <v-text-field
        style="max-width: 400px;"
        v-model="search"
        append-icon="search"
        :label="$tc('global.buscar')"
        clearable
        outlined
        solo
        flat
        dense
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table
        v-if="!loading"
        :headers="headers"
        :search="search"
        :items="veiculos"
        :items-per-page="-1"
        class="data-tables "
        :class="{ 'data-tables__row-click': vueVeiculo }"
        @click:row="goToVeiculo"
      ></v-data-table>
      <Carregando v-else />
    </v-card-text>
  </v-card>
</template>

<script>
import { getVeiculos } from "@/services/api/veiculos.api.js";
import { mapGetters } from "vuex";

export default {
  name: "ListaVeiculos",
  components: {},
  data: () => ({
    loading: true,
    veiculos: [],
    search: "",
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$tc("veiculo.placa"),
          value: "placa",
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: this.$tc("veiculo.cor"),
          value: "cor",
        },
        {
          text: this.$tc("global.tipo"),
          value: "tipo",
        },
      ];
    },
    ...mapGetters("Componentes", ["getAccess"]),
    vueVeiculo() {
      return this.getAccess("vueVeiculo", "visualizar");
    },
  },
  methods: {
    goToVeiculo(item) {
      if (this.vueVeiculo) {
        this.$store.commit("UPDATE_DYNAMICCRUMB", item.placa);
        this.$router.push({
          name: "Veiculo",
          params: { veiculoId: item.id },
        });
      }
    },
    getVeiculos() {
      this.loading = true;
      this.$Progress.start();
      getVeiculos()
        .then((response) => {
          this.veiculos = response;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getVeiculos();
  },
};
</script>

<style scoped lang="scss"></style>
