import api from "../httpClient.js";

export async function getVeiculos() {
  const response = await api.get("/veiculos");
  return response.data.veiculos;
}

export async function getVeiculo(id) {
  const response = await api.get(`/veiculos/${id}`);
  return response.data.veiculo;
}

export async function searchVeiculos(filtros) {
  const response = await api.post(`/veiculos${filtros}`);
  return response.data.veiculos;
}

export function putVeiculo(id, veiculo) {
  let body = {};
  for (let key in veiculo) {
    body[key] = veiculo[key];
  }
  return api.put(`/veiculos/${id}`, body);
}

export function postVeiculo(veiculo) {
  let body = {};
  for (let key in veiculo) {
    body[key] = veiculo[key];
  }
  return api.post("/veiculos/add", body);
}

// RESPONSAVEIS

export async function getResponsaveis(veiculo_id) {
  if (veiculo_id) {
    const response = await api.get(
      `/veiculosresponsaveis?veiculo_id=${veiculo_id}`
    );
    return response.data.veiculo_responsavel;
  } else {
    const response = await api.get("/veiculosresponsaveis");
    return response.data.veiculo_responsavel;
  }
}

export async function getUsersNotIn(veiculo_id) {
  const response = await api.get(
    `/veiculosresponsaveis-usernotin/${veiculo_id}`
  );
  return response.data.usersNotIn;
}

export function deleteResp(id) {
  return api.delete(`/veiculosresponsaveis/${id}`);
}

export function postRespVeiculo(responsavel) {
  let body = {};
  for (let key in responsavel) {
    body[key] = responsavel[key];
  }
  return api.post("/veiculosresponsaveis/add", body);
}

export async function getLogs(veiculo_id) {
  if (veiculo_id) {
    const response = await api.get(`/veiculoslogs?veiculo_id=${veiculo_id}`);
    return response.data.veiculo_log;
  } else {
    const response = await api.get("/veiculoslogs");
    return response.data.veiculo_log;
  }
}
